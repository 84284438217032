import { default as Excel } from "exceljs";
import FileSaver from "file-saver";

class control_sheet_excel {
    workbook = null
    worksheet = null
    datos = null
    image = null
    fontName = 'Century Gothic'

    bordes = {
        top: { style: "thin", color: { argb: "000" } },
        left: { style: "thin", color: { argb: "000" } },
        bottom: { style: "thin", color: { argb: "000" } },
        right: { style: "thin", color: { argb: "000" } },
    };

    constructor(params) {
        this.workbook = new Excel.Workbook();
        this.worksheet = this.workbook.addWorksheet("Hoja 1")
        this.image = params.img_base64
        this.datos = params.datos
    }

    init() {
        const workbook = this.workbook

        workbook.creator = 'AYS';
        workbook.lastModifiedBy = 'AYS';
        workbook.created = new Date();

        this.header()
    }

    header() {
        const worksheet = this.worksheet
        // add imagen

        worksheet.mergeCells('A1:B4')
        let image = this.workbook.addImage({
            base64: this.image,
            extension: 'png'
        })

        worksheet.addImage(image, 'A1:B4')
        worksheet.getCell('A1').border = this.bordes
        worksheet.getColumn('A').width = 15

        // 

        worksheet.getColumn('C').width = 50

        worksheet.mergeCells('C1:E4')
        worksheet.getCell('C1').value = 'HOJA DE CONTROL'
        worksheet.getCell('C1').style = {
            font: { name: this.fontName, size: 11, bold: true },
            alignment: { horizontal: 'center', vertical: 'middle' }
        }

        this.addBorder(['C', 'D', 'E'], this.bordes, 1)

        // 
        worksheet.mergeCells('F1:H4')
        // let image2 = this.workbook.addImage({
        //     base64: img_format,
        //     extension: 'png'
        // })

        // worksheet.addImage(image2, {
        //     tl: { col: 5.2, row: 0.8 },
        //     br: { col: 7.8, row: 3.8 },
        //     ext: { width: 50, height: 50 }
        // })

        this.addBorder(['F', 'G', 'H'], this.bordes, 1)

        this.subHeader()
    }

    subHeader() {
        const worksheet = this.worksheet
        worksheet.mergeCells('A5:C6')
        worksheet.getCell('A5').border = this.bordes

        worksheet.mergeCells('D5:F5')
        worksheet.getCell('D5').value = 'Hoja de control No. '
        worksheet.getCell('D5').style = {
            font: { name: this.fontName, size: 10 },
            alignment: { horizontal: 'right', vertical: 'bottom' },
        }

        this.addBorder(['D', 'E', 'F'], this.bordes, 5)

        worksheet.mergeCells('D6:F6')
        worksheet.getCell('D6').value = 'Fecha de apertura de hoja: '
        worksheet.getCell('D6').style = {
            font: { name: this.fontName, size: 10 },
            alignment: { horizontal: 'right', vertical: 'bottom' }
        }

        this.addBorder(['D', 'E', 'F'], this.bordes, 6)

        worksheet.mergeCells('G5:H5')
        this.addBorder(['G', 'H'], this.bordes, 5)

        worksheet.mergeCells('G6:H6')
        this.addBorder(['G', 'H'], this.bordes, 6)

        // 

        worksheet.mergeCells('A7:C7')
        worksheet.getCell('A7').value = 'Número del expeddiente o contrato: '

        worksheet.mergeCells('D7:E7')

        worksheet.getCell('F7').value = 'Fecha: '


        worksheet.mergeCells('G7:H7')

        this.addBorder(['A', 'D', 'F', 'H'], this.bordes, 7)

        // 
        worksheet.mergeCells('A8:B8')
        worksheet.getCell('A8').value = 'Objeto o nombre: '
        worksheet.getCell('C8').value = this.datos?.header?.lgj_nmbre || ''

        worksheet.mergeCells('C8:H8')
        this.addBorder(['A', 'C'], this.bordes, 8)

        worksheet.mergeCells('A9:C9')
        worksheet.getCell('A9').value = 'Carpeta o expediente No.: '

        worksheet.mergeCells('D9:E9')
        worksheet.getCell('D9').value = this.datos?.header?.lgj_nmro || ''

        worksheet.getCell('F9').value = 'Tomo No.: '
        worksheet.mergeCells('G9:H9')

        this.addBorder(['A', 'D', 'F', 'G'], this.bordes, 9)
        this.headerBody()
    }

    headerBody() {
        const worksheet = this.worksheet

        worksheet.getColumn('B').width = 10

        worksheet.getRow(11).height = 30
        worksheet.getRow(12).height = 50

        worksheet.mergeCells('A11:A12')
        worksheet.getCell('A11').value = 'FECHA DOCUM.'
        worksheet.getCell('A11').style = {
            font: { name: this.fontName, size: 9, bold: true },
            alignment: { horizontal: 'center', vertical: 'middle', wrapText: true }
        }

        worksheet.mergeCells('B11:B12')
        worksheet.getCell('B11').value = 'CANTIDAD DE FOLIOS'
        worksheet.getCell('B11').style = {
            font: { name: this.fontName, size: 9, bold: true },
            alignment: { horizontal: 'center', vertical: 'middle', wrapText: true }
        }

        worksheet.mergeCells('C11:C12')
        worksheet.getCell('C11').value = 'DESCRIPCIÓN DEL DOCUMENTO'
        worksheet.getCell('C11').style = {
            font: { name: this.fontName, size: 9, bold: true },
            alignment: { horizontal: 'center', vertical: 'middle', wrapText: true }
        }

        worksheet.mergeCells('D11:H11')
        worksheet.getCell('D11').value = 'CONTROL DE ARCHIVO'
        worksheet.getCell('D11').style = {
            font: { name: this.fontName, size: 9, bold: true },
            alignment: { horizontal: 'center', vertical: 'middle', wrapText: true }
        }

        worksheet.getCell('D12').value = 'FECHA DE RECIBO'
        worksheet.getCell('D12').style = {
            font: { name: this.fontName, size: 9, bold: true },
            alignment: { horizontal: 'center', vertical: 'middle', wrapText: true }
        }

        worksheet.getCell('E12').value = 'No. FOLIO EN EL QUE QUEDA'
        worksheet.getCell('E12').style = {
            font: { name: this.fontName, size: 9, bold: true },
            alignment: { horizontal: 'center', vertical: 'middle', wrapText: true }
        }

        worksheet.getCell('F12').value = 'NOMBRE DE QUIEN ENTREGA'
        worksheet.getCell('F12').style = {
            font: { name: this.fontName, size: 9, bold: true },
            alignment: { horizontal: 'center', vertical: 'middle', wrapText: true }
        }

        worksheet.getCell('G12').value = 'FIRMA QUIEN ENTREGA'
        worksheet.getCell('G12').style = {
            font: { name: this.fontName, size: 9, bold: true },
            alignment: { horizontal: 'center', vertical: 'middle', wrapText: true }
        }

        worksheet.getCell('H12').value = 'FIRMA QUIEN RECIBE'
        worksheet.getCell('H12').style = {
            font: { name: this.fontName, size: 9, bold: true },
            alignment: { horizontal: 'center', vertical: 'middle', wrapText: true }
        }

        this.addBorder(['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'], this.bordes, 11)
        this.addBorder(['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'], this.bordes, 12)

        this.body()
    }

    body() {
        let count = 0;
        let quantity = this.datos.body.length > 24 ? this.datos.body.length : 24

        const rows = new Array(quantity).fill('').map(e => e = count++);
        const worksheet = this.worksheet
        const columns = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H']
        let rowMain = 12

        console.log("this.datos", this.datos)

        for (const row of rows) {
            rowMain++
            worksheet.getRow(rowMain).height = 32

            let body = this.datos.body
            worksheet.getCell(`A${rowMain}`).value = body[row]?.fecha_doc || ''
            worksheet.getCell(`A${rowMain}`).style = { alignment: { wrapText: true } }

            worksheet.getCell(`B${rowMain}`).value = body[row]?.dcm_nflios || ''
            worksheet.getCell(`B${rowMain}`).style = { alignment: { horizontal: 'center' } }

            worksheet.getCell(`C${rowMain}`).value = body[row]?.dcm_asunto || ''

            for (const col of columns) {
                worksheet.getCell(`${col}${rowMain}`).border = this.bordes
            }
        }

        this.create_report()
    }

    addBorder(columns = [], borders = {}, row = 0) {
        const worksheet = this.worksheet

        for (const item of columns) {
            worksheet.getCell(`${item}${row}`).border = borders
        }
    }

    async create_report() {
        let name = `report-${Date.now()}`

        try {
            if (typeof window == "undefined") {
                await this.workbook.xlsx.writeFile(`${name}.xlsx`);
            } else {
                const buffer = await this.workbook.xlsx.writeBuffer()
                const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                FileSaver.saveAs(blob, name + 'xlsx');
            }

            console.log(0, 'Reporte generado')
        } catch (error) {
            console.log(-1, error)
        }
    }
}

export default control_sheet_excel